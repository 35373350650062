<template>
  <div class="netzero animation" style="animation-delay: 1s" v-if="render">
    <!-- intro -->
    <v-container fluid>
      <v-row>
        <v-col class="pa-0 intro position-relative">
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              netZeroSingleton.img_intro.path
            "
            width="100%"
            height="100%"
            class="intro__asset"
            eager
          ></v-img>
          <!-- <video
            style="object-fit: cover"
            class="intro__asset"
            loop
            playsinline
            width="100%"
            height="100%"
            :autoplay="autoplay"
          >
            <source src="@/assets/img/videoplayback.mp4" />
          </video> -->
          <v-row no-gutters justify="center" class="flex-column fill-height">
            <v-col cols="auto" class="animation" style="animation-delay: 2s">
              <div class="logo-header mx-auto" v-if="$vuetify.breakpoint.smAndUp"></div>
              <router-link to="/" v-else>
                <v-img
                  alt="Marisol Logo"
                  class="shrink logo-header mx-auto"
                  contain
                  src="@/assets/icons/MariSolMalibu_logo.svg"
                  transition="scale-transition"
                />
              </router-link>
              <!-- btn para el menú mobile -->
              <div
                v-if="$vuetify.breakpoint.xs"
                class="text-center mt-6 d-sm-none"
              >
                <v-btn
                  @click="$emit('open')"
                  :ripple="false"
                  text
                  class="letter-spacing-0 not-text-transform"
                >
                  <span class="continuos-texts white--text">Menu</span>
                </v-btn>
              </div>
              <!-- /btn para el menú mobile -->
            </v-col>
            <v-col
              cols="auto"
              class="position-relative mt-auto in-netzero-margin"
            >
              <v-row no-gutters justify="center" align="center">
                <v-col cols="auto" class="text-center">
                  <span
                    class="white--text titles-medium d-block sabon-regular animation line-height-title-intro"
                    style="animation-delay: 3s"
                    v-html="netZeroSingleton.title_intro"
                  ></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="auto"
              class="mt-auto position-relative animation"
              style="animation-delay: 2s"
            >
              <v-row no-gutters justify="center" align="end">
                <v-col cols="auto">
                  <v-img
                    src="@/assets/icons/MALIBU_logo.svg"
                    class="intro__logo-2"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->

    <!-- segundo bloque -->
    <v-container
      fluid
      class="block-second-net-zero pb-0 animation-each"
      style="background: rgb(232, 230, 223)"
    >
      <v-row>
        <v-col cols="12" md="6" class="block-second pb-0">
          <div class="first">
            <v-row
              justify="center"
              class="fill-height"
              align="center"
              no-gutters
            >
              <v-col
                cols="auto"
                class="text-center text-md-left line-height-second-block"
              >
                <div class="max-width-intro-texts">
                  <p
                    class="continuos-texts-big founders-grotesk-regular first-paragraph"
                  >
                    {{ netZeroSingleton.texts_intro.first_paragraph }}
                  </p>

                  <p class="continuos-texts-big founders-grotesk-regular mb-0">
                    {{ netZeroSingleton.texts_intro.second_paragraph }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-0">
          <div class="padding-custom-in-image">
            <v-img
              :src="
                'https://content.marisolmalibu.com' +
                netZeroSingleton.img2_intro.path
              "
              class="block-second-net-zero__img"
              eager
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- /segundo bloque -->

    <!-- tercer bloque -->
    <v-container fluid class="pb-0">
      <v-row no-gutters class="block__second">
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
            <div class="max-width-title-block">
                <span
                  class="sabon-regular block__second__title-second-paragraph"
                  v-html="netZeroSingleton.title_zero_certified"
                >
                </span>
            </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /tercer bloque -->

    <v-container fluid class="block-fourth padding-custom-general-3 pb-0">
      <v-row no-gutters>
        <v-col>
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              netZeroSingleton.img_ilfi.path
            "
            class="zero-certified-img"
            id="firstImage"
            style="transition: opacity 4s ease"
            eager
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="div-info-ilfi animation-each" no-gutters>
        <v-col cols="12" sm="auto">
          <v-row no-gutters justify="center" justify-sm="start">
            <v-col
              cols="auto"
              order="1"
              order-sm="0"
              class="text-center text-sm-left"
            >
              <div class="max-width-ilfi-intro">
                <span
                  class="xx-small-texts line-height-random-2 d-block"
                  v-if="$vuetify.breakpoint.smAndUp"
                >
                  {{ netZeroSingleton.img_description_ilfi.description }}
                </span>

                <v-img
                  :src="
                    'https://content.marisolmalibu.com' +
                    netZeroSingleton.img_description_ilfi.img.path
                  "
                  class="logo-ilfi"
                  contain
                  eager
                ></v-img>

                <span
                  class="xx-small-texts line-height-random-2 d-block"
                  v-if="$vuetify.breakpoint.xs"
                  >{{ netZeroSingleton.img_description_ilfi.description }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          class="margin-left-second-column px-7 px-sm-3"
        >
          <div class="box text-center text-sm-left">
            <div class="box-with-border">
              <!-- <span
                class="continuos-texts-big-2 founders-grotesk-bold d-block d-sm-inline"
                >Quantifying </span
              ><span class="continuos-texts-big-2"
                >energy consumption and embodied carbon throughout the
                construction process</span
              > -->

              <span
                class="continuos-texts-big-2"
                v-html="netZeroSingleton.texts_ilfi.quantifying"
              ></span>
            </div>
            <div class="box-with-border">
              <!-- <span
                class="continuos-texts-big-2 founders-grotesk-bold d-block d-sm-inline"
                >Reducing </span
              ><span class="continuos-texts-big-2"
                >operational and embodied carbon emissions</span
              > -->
              <span
                class="continuos-texts-big-2"
                v-html="netZeroSingleton.texts_ilfi.reducing"
              ></span>
            </div>
            <div class="box-with-border">
              <!-- <span
                class="continuos-texts-big-2 founders-grotesk-bold d-block d-sm-inline"
                >Neutralizing </span
              ><span class="continuos-texts-big-2"
                >all carbon emissions through renewable energy, carbon-storing
                materials, and if needed–offsets
              </span> -->
              <span
                class="continuos-texts-big-2"
                v-html="netZeroSingleton.texts_ilfi.neutralizing"
              ></span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      fluid
      class="pt-0 padding-custom-general-3 rewrite-in-tablet-v xx-small-gradient"
    >
      <v-row class="mt-0 animation-each">
        <v-col cols="12" sm="auto" class="pt-0">
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              netZeroSingleton.img_zero_series.path
            "
            class="img-ilfi-big"
            eager
          ></v-img>
        </v-col>
        <v-col cols="12" sm="auto" class="margin-left-second-column-2">
          <div class="box with-padding text-center text-sm-left">
            <span class="box__span sabon-regular">
              {{ netZeroSingleton.text_zero_series }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- quinto bloque -->
    <v-container fluid class="half-gradient padding-in-zero-up-front">
      <v-row no-gutters class="block__second animation-each">
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
             <div class="max-width-title-block">
                <span
                  class="block__second__title-second-paragraph sabon-regular"
                  v-html="netZeroSingleton.title_zero_up_front"
                ></span>
             </div>

              <p
                class="mb-0 xx-small-texts block__second--description max-width-23"
              >
                {{ netZeroSingleton.description_zero_up_front }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /quinto bloque -->

    <v-container
      fluid
      class="padding-custom-general-3 pt-0 pb-0"
      id="tabs"
      style="background: rgb(232, 230, 223)"
    >
      <v-row class="pt-10 pt-sm-0">
        <v-col class="pt-0 pt-sm-3 animation-each">
          <!-- v-bind:fixed-tabs="$vuetify.breakpoint.xs ? false : true" -->
          <v-tabs
            v-model="model"
            fixed-tabs
            slider-color="transparent"
            centered
            background-color="rgb(232, 230, 223)"
            slider-size="0"
          >
            <v-tab :ripple="false">
              <span
                class="continuos-texts not-text-transform letter-spacing-0"
                >{{ netZeroSingleton.lumber_item.title }}</span
              >
            </v-tab>
            <v-tab :ripple="false">
              <span
                class="continuos-texts not-text-transform letter-spacing-0"
                >{{ netZeroSingleton.concrete_item.title }}</span
              >
            </v-tab>
            <v-tab :ripple="false">
              <span
                class="continuos-texts not-text-transform letter-spacing-0"
                >{{ netZeroSingleton.steel_item.title }}</span
              >
            </v-tab>
          </v-tabs>

          <div class="container-tabs-content">
            <v-tabs-items
              v-model="model"
              style="background: rgb(232, 230, 223)"
            >
              <v-tab-item
                transition="fade-transition"
                reverse-transition="fade-transition"
              >
                <v-card color="transparent" elevation="0" tile>
                  <v-row
                    align="center"
                    justify="center"
                    justify-sm="space-between"
                    no-gutters
                    class="padding-top-row"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="text-center text-sm-left pb-10 pb-sm-12 pb-sm-3"
                      style="line-height: 1.22"
                    >
                      <div class="max-width-tab-item-text mx-auto mx-sm-0">
                        <span class="continuos-texts">{{
                          netZeroSingleton.lumber_item.description
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <v-img
                        :src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.lumber_item.image.path
                        "
                        eager
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <v-tab-item
                transition="fade-transition"
                reverse-transition="fade-transition"
              >
                <v-card color="transparent" elevation="0" tile>
                  <v-row
                    align="center"
                    justify="center"
                    justify-sm="space-between"
                    no-gutters
                    class="padding-top-row"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="text-center text-sm-left pb-12 pb-sm-3"
                      style="line-height: 1.22"
                    >
                      <div class="max-width-tab-item-text mx-auto mx-sm-0">
                        <span class="continuos-texts"
                          >{{ netZeroSingleton.concrete_item.description }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <v-img
                        :src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.concrete_item.image.path
                        "
                        eager
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

              <v-tab-item
                transition="fade-transition"
                reverse-transition="fade-transition"
              >
                <v-card color="transparent" elevation="0" tile>
                  <v-row
                    align="center"
                    justify="center"
                    justify-sm="space-between"
                    no-gutters
                    class="padding-top-row"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="text-center text-sm-left pb-12 pb-sm-3"
                      style="line-height: 1.22"
                    >
                      <div class="max-width-tab-item-text mx-auto mx-sm-0">
                        <span class="continuos-texts">
                          {{ netZeroSingleton.steel_item.description }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <v-img
                        :src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.steel_item.image.path
                        "
                        eager
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>

      <v-row
        justify="space-between"
        class="margin-custom-top text-center text-sm-left animation-each"
        no-gutters
      >
        <v-col cols="12" md="auto" class="text-center text-md-left">
          <div class="max-width-materials-1">
            <span class="xx-small-texts d-block margin-text-top">
              {{ netZeroSingleton.description_zero_up_front_materials }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="auto" class="px-0 same-width-up">
          <div id="textParallaxContainer" class="parallax-div-items">
            <div id="textParallax">
              <span class="texts-big position-relative">
                <span
                  class="sabon-regular d-block space-between-items"
                  v-for="(word, w) in netZeroSingleton.materials_zero_up_front"
                  v-bind:key="w"
                >
                  {{ word.value }}
                </span>
                <span
                  class="sabon-regular d-block space-between-items"
                  v-for="(word, w) in netZeroSingleton.materials_zero_up_front"
                  v-bind:key="w + '-2'"
                >
                  {{ word.value }}
                </span>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- septimo bloque -->
    <v-container fluid class="pb-0 pt-0" style="background: rgb(232, 230, 223)">
      <v-row no-gutters class="block__second animation-each">
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
             <div class="max-width-title-block">
                <span
                  class="block__second__title-second-paragraph sabon-regular"
                  v-html="netZeroSingleton.title_zero_combustion"
                ></span>
             </div>

              <p
                class="mb-0 xx-small-texts block__second--description max-width-23"
              >
                {{ netZeroSingleton.description_zero_combustion }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pa-0 padding-in-sun-and-splendor-2 row-2-sun">
          <v-row
            justify="space-between"
            align="center"
            class="margin-top-custom-row-3 animation-each"
            no-gutters
          >
            <!-- carousel tablet v to up -->
            <v-col
              class="div-zero-combustion"
              cols="12"
              sm="auto"
              order="1"
              order-sm="0"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-img
                :src="
                  'https://content.marisolmalibu.com' +
                  netZeroSingleton.accordion_zero_combustion_1.image.path
                "
                class="img-zero-combustion"
                :class="carouselZeroCombustion == 0 ? 'opacity-1' : 'opacity-0'"
                style="transition: opacity 0.5s 0.2s ease"
                eager
              ></v-img>

              <v-img
                :src="
                  'https://content.marisolmalibu.com' +
                  netZeroSingleton.accordion_zero_combustion_2.image.path
                "
                class="img-zero-combustion"
                position="bottom center"
                :class="carouselZeroCombustion == 1 ? 'opacity-1' : 'opacity-0'"
                style="transition: opacity 0.5s 0.2s ease"
                eager
              ></v-img>

              <v-img
                :src="
                  'https://content.marisolmalibu.com' +
                  netZeroSingleton.accordion_zero_combustion_3.image.path
                "
                class="img-zero-combustion"
                :class="carouselZeroCombustion == 2 ? 'opacity-1' : 'opacity-0'"
                style="transition: opacity 0.5s 0.2s ease"
                eager
              ></v-img>
            </v-col>
            <!-- /carousel tablet v to up -->

            <v-col cols="12" sm="auto" class="column-panels-xs mx-auto">
              <div>
                <v-expansion-panels accordion tile v-model="expansionPanels">
                  <v-expansion-panel @click="carouselZeroCombustion = 0">
                    <v-expansion-panel-header expand-icon="">
                      <span class="text-random-3">{{
                        netZeroSingleton.accordion_zero_combustion_1.title
                      }}</span>
                      <v-img
                        src="@/assets/icons/plus.svg"
                        class="arrow-icon"
                      ></v-img>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="d-block text-random-2">
                        {{
                          netZeroSingleton.accordion_zero_combustion_1
                            .description
                        }}
                      </span>
                      <v-img
                        v-if="$vuetify.breakpoint.xs"
                        v-bind:src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.accordion_zero_combustion_1.image
                            .path
                        "
                        class="img-zero-combustion"
                        eager
                      ></v-img>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel @click="carouselZeroCombustion = 1">
                    <v-expansion-panel-header expand-icon="">
                      <span class="text-random-3">{{
                        netZeroSingleton.accordion_zero_combustion_2.title
                      }}</span>
                      <v-img
                        src="@/assets/icons/plus.svg"
                        class="arrow-icon"
                      ></v-img>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="d-block text-random-2">
                        {{
                          netZeroSingleton.accordion_zero_combustion_2
                            .description
                        }}
                      </span>
                      <v-img
                        v-if="$vuetify.breakpoint.xs"
                        v-bind:src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.accordion_zero_combustion_2.image
                            .path
                        "
                        class="img-zero-combustion"
                        position="bottom center"
                      ></v-img>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel @click="carouselZeroCombustion = 2">
                    <v-expansion-panel-header expand-icon="">
                      <span class="text-random-3">{{
                        netZeroSingleton.accordion_zero_combustion_3.title
                      }}</span>
                      <v-img
                        src="@/assets/icons/plus.svg"
                        class="arrow-icon"
                      ></v-img>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="d-block text-random-2">
                        {{
                          netZeroSingleton.accordion_zero_combustion_3
                            .description
                        }}
                      </span>
                      <v-img
                        v-if="$vuetify.breakpoint.xs"
                        v-bind:src="
                          'https://content.marisolmalibu.com' +
                          netZeroSingleton.accordion_zero_combustion_3.image
                            .path
                        "
                        class="img-zero-combustion"
                      ></v-img>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-expand-transition>
          <v-col
            v-if="infoZeroCombustion2"
            cols="12"
            class="column-info-zero-combustion padding-custom-general-3"
          >
            <v-row
              class="border-top-custom text-center text-sm-left"
              no-gutters
              justify="center"
              justify-sm="start"
            >
              <v-col cols="12" sm="auto" class="column-start">
                <span
                  class="xx-small-texts rewrite d-block founders-grotesk-light pre-line"
                >
                  {{netZeroSingleton.description_fossil_fuel}}
                </span>
              </v-col>
              <v-col cols="6" sm="auto" class="column-middle">
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                  v-for="(item, i) in netZeroSingleton.items_fossil_fuel_1"
                  :key="i"
                  >{{item.value}}
                </span>
                <!-- <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                  >CO2 Water Heaters
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Induction Cooktops
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Hydronic Heating & Cooling
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Pool Heat Pumps
                </span> -->
              </v-col>
              <v-col cols="6" sm="auto">
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                   v-for="(item, i) in netZeroSingleton.items_fossil_fuel_2"
                  :key="i"
                >
                  {{item.value}}
                </span>
                <!-- <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Tesla Batteries
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  EV Chargers
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Electric Fireplaces
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Electric BBQs
                </span>
                <span
                  class="sabon-regular text-random d-block margin-bottom-in-zero-combustion-info"
                >
                  Heat Pump Dryers
                </span> -->
              </v-col>
            </v-row>
          </v-col>
        </v-expand-transition>

        <!-- NO SE USA POR EL MOMENTO -->
        <v-col
          cols="12"
          class="column-info-zero-combustion padding-custom-general-3"
          :class="{ 'mt-0': infoZeroCombustion }"
          v-if="false"
        >
          <v-row justify="space-between" no-gutters class="div-btn-close">
            <v-col
              cols="12"
              sm="auto"
              class="line-across"
              :class="!infoZeroCombustion ? 'opacity-0' : 'opacity-1'"
              style="transition: opacity 0.5s ease"
            >
              <div class="line-middle"></div>
            </v-col>
            <v-col cols="12" sm="auto">
              <v-btn
                class="btn-25 rewrite-size letter-spacing-0 not-text-transform mt-0"
                tile
                color="black"
                elevation="0"
                :ripple="false"
                @click="infoZeroCombustion = !infoZeroCombustion"
              >
                <template v-if="infoZeroCombustion">
                  <span class="continuos-texts white--text">Close</span>
                </template>
                <template v-else>
                  <span class="continuos-texts white--text">See more</span>
                </template>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="auto" class="line-across">
              <div
                class="line-middle"
                :class="!infoZeroCombustion ? 'opacity-0' : 'opacity-1'"
                style="transition: opacity 0.5s ease"
              ></div>
            </v-col>
          </v-row>
        </v-col>
        <!-- /NO SE USA POR EL MOMENTO -->
      </v-row>
    </v-container>
    <!-- /septimo bloque -->

    <!-- octavo bloque -->
    <v-container fluid class="pb-0">
      <v-row
        no-gutters
        class="block__second margin-random-165185 animation-each"
      >
        <v-col>
          <v-row no-gutters class="fill-height" justify="center" align="center">
            <v-col cols="auto" class="text-center">
              <div class="max-width-title-block">
                <span class="block__second__title-second-paragraph sabon-regular" v-html="netZeroSingleton.title_zero_tradeoffs">
                </span>
              </div>

              <p class="mb-0 xx-small-texts block__second--description max-width-23">{{netZeroSingleton.description_zero_tradeoffs}}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /octavo bloque -->

    <v-container
      fluid
      class="block__third padding-custom-general-2 animation-each"
    >
      <v-row justify="center">
        <v-col cols="12" class="px-0 pt-0">
          <v-row
            no-gutters
            justify="center"
            align="center"
            class="fill-height position-relative"
          >
            <div class="d-flex dots-div in-netzero align-self-end">
              <span
                :class="{ active: dotsOwl == 5 || dotsOwl == 2 }"
                @click="
                  dotsOwl = 5;
                  owlCarN3();
                "
                class="dots-custom cursor-pointer"
              ></span>
              <span
                :class="{ active: dotsOwl == 3 }"
                @click="
                  dotsOwl = 3;
                  owlCarN2();
                "
                class="dots-custom cursor-pointer"
              ></span>
              <span
                :class="{ active: dotsOwl == 4 || dotsOwl == 1 }"
                @click="
                  dotsOwl = 4;
                  owlCarN();
                "
                class="dots-custom cursor-pointer"
              ></span>
            </div>

            <v-col cols="12" sm="5" order="1" order-sm="0" class="pt-3 d-none d-sm-block">
              <v-row no-gutters justify="center">
                <v-col
                  cols="auto"
                  class="block__third__ml-custom margin-custom-slider-text text-center text-sm-left position-relative"
                >
                  <div v-if="dotsOwl == 5 || dotsOwl == 2">
                    <div>
                      <span
                        class="subtitles-big line-height-title founders-grotesk-light"
                        >Breathe <br />
                        Free
                      </span>
                    </div>

                    <div>
                      <p
                        class="continuos-texts block__third__margin-p line-height-description"
                      >
                        Zehnder Ventilation Systems <br />
                        and leading water purification <br />
                        systems constantly filter out <br />
                        allergens, toxins, and pollutants to <br />
                        deliver medical-grade air and <br />
                        pristine water.
                      </p>
                    </div>
                  </div>

                  <div v-if="dotsOwl == 3">
                    <div>
                      <span
                        class="subtitles-big line-height-title founders-grotesk-light"
                        >Eat <br />
                        Local
                      </span>
                    </div>

                    <div>
                      <p
                        class="continuos-texts block__third__margin-p line-height-description"
                      >
                        Regenerative gardens on each <br />
                        property provide edible produce <br />
                        365 days a year, while on-site <br />
                        beehives support native flora.
                      </p>
                    </div>
                  </div>

                  <div v-if="dotsOwl == 4">
                    <div>
                      <span
                        class="subtitles-big line-height-title founders-grotesk-light"
                        >Live <br />
                        Easy
                      </span>
                    </div>

                    <div>
                      <p
                        class="continuos-texts block__third__margin-p line-height-description"
                      >
                        Complimentary preventative maintenance <br />
                        service from a dedicated on-call <br />
                        estate manager.
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="7" class="block__third__second-column">
              <div class="slider position-relative">
                <carousel
                  :items="1"
                  :nav="false"
                  :margin="5"
                  :loop="true"
                  :dots="false"
                  :responsive="{
                    600: {
                      items: 1.2,
                    },
                  }"
                  @changed="changedOwlCarousel"
                  ref="owlCar"
                >
                <template v-for="(image, imgt) in netZeroSingleton.carousel_zero_tradeoffs">
                  <div :key="imgt">

                    <img
                      v-bind:src="
                            'https://content.marisolmalibu.com' +
                            image.value.image
                              .path
                          "
                          :key="imgt"
                      alt=""
                    />
                    <div v-if="$vuetify.breakpoint.xs" class="text-center pt-3">
                      <div v-if="imgt == 0">
                        <div class="text-center">
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >Breathe <br />
                            Free
                          </span>
                        </div>

                        <div class="text-center">
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            Zehnder Ventilation Systems <br />
                            and leading water purification <br />
                            systems constantly filter out <br />
                            allergens, toxins, and pollutants to <br />
                            deliver medical-grade air and <br />
                            pristine water.
                          </p>
                        </div>
                      </div>

                      <div v-if="imgt == 1">
                        <div class="text-center">
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >Eat <br />
                            Local
                          </span>
                        </div>

                        <div class="text-center">
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            Regenerative gardens on each <br />
                            property provide edible produce <br />
                            365 days a year, while on-site <br />
                            beehives support native flora.
                          </p>
                        </div>
                      </div>

                      <div v-if="imgt == 2">
                        <div class="text-center">
                          <span
                            class="subtitles-big line-height-title founders-grotesk-light"
                            >Live <br />
                            Easy
                          </span>
                        </div>

                        <div class="text-center">
                          <p
                            class="continuos-texts block__third__margin-p line-height-description"
                          >
                            Complimentary preventative maintenance<br />
                            service from a dedicated on-call <br />
                            estate manager.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                  <template slot="next">
                    <div
                      id="nextOwl"
                      class="div-for-arrow cursor-pointer d-none d-sm-block"
                    >

                      <v-img
                        src="@/assets/icons/Flecha-derecha-blanca.svg"
                        class="arrow--right arrow-breath-free"
                      ></v-img>
                    </div>
                  </template>
                  <template slot="prev">
                    <div id="prevOwl" class="d-none">
                      <v-img
                        src="@/assets/icons/Flecha-izquierda.svg"
                        class="arrow--right"
                      ></v-img>
                    </div>
                  </template>
                </carousel>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="padding-custom-general-4 animation-each">
      <v-row
        justify="space-between"
        class="margin-custom-top text-center text-sm-left"
      >
        <v-col cols="12" md="auto" class="text-center text-md-left">
          <div class="max-width-materials-1">
            <span class="xx-small-texts">
             <!-- {{netZeroSingleton.description_zero_up_front_materials}} -->
             {{netZeroSingleton.upgrades_zer_tradeoffst_title}}
              <!-- Every aspect is designed with intent,
              <br v-if="$vuetify.breakpoint.xs" />
              and <br v-if="$vuetify.breakpoint.smAndUp" />
              without compromise. -->
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="auto">
          <div
            id="textParallaxContainer2"
            class="same-width-up number-2 position-relative parallax-div-items-2"
          >
            <div>
              <div id="textParallax2">
                <span class="texts-big position-relative">
                  <span class="sabon-regular d-block space-between-items" v-for="(word, w2) in netZeroSingleton.upgrades_zero_tradeoffs"
                  v-bind:key="w2">
                    {{word.value}}
                  </span>

                  <span class="sabon-regular d-block space-between-items" v-for="(word, w2) in netZeroSingleton.upgrades_zero_tradeoffs"
                  v-bind:key="w2+'-2'">
                    {{word.value}}
                  </span>
                  
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-0">
      <v-row class="row-parallax in-netzero">
        <v-col
          cols="12"
          class="px-0"
          style="overflow-x: visible"
          :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
        >
          <div class="parallax-container">
            <div class="parallax" :style="
                'background-image: url(https://content.marisolmalibu.com' +
                netZeroSingleton.image_parallax.path +
                ');'
              "></div>
            <div class="see-yourself-div text-center">
              <span class="white--text titles-xxl d-block sabon-regular"  v-html="netZeroSingleton.text_parallax"
                >
                </span
              >
              <!-- <v-btn
                class="btn-2 letter-spacing-0 not-text-transform"
                tile
                color="white"
                elevation="0"
                :ripple="false"
                to="/contact"
              >
                <span class="continuos-texts">Book a showing</span></v-btn
              > -->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Menú mobile-->
    <Menu :visible="open_menu" @close="open_menu = false"></Menu>
    <!-- /Menú mobile-->
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import carousel from "vue-owl-carousel";
import VueRellax from "vue-rellax";
import VueMeta from 'vue-meta'

Vue.use(VueRellax);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
  metaInfo: {
    title: 'Zero Carbon Homes | Marisol Malibu',
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: 'en'
    },
  }
});

export default {
  
  metaInfo: {
    title: 'Net Zero Home | Marisol Malibu',
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      {vmid: 'og:description', property: 'og:description', name:'description', content: "We’ve seen first-hand the impact of the climate crisis on the places we love. We must do all we can to slow and reverse those effects, especially as homebuilders, as buildings account for 39% of global carbon emissions through construction and operation."}
    ]
  },
  name: "NetZero",
  components: {
    carousel,
  },
  computed: {
    heightCarousel() {
      var height = 306;

      if (this.$vuetify.breakpoint.width >= 600) {
        height = 328;
      }

      if (this.$vuetify.breakpoint.width >= 960) {
        height = 437;
      }

      if (this.$vuetify.breakpoint.width >= 1264) {
        height = 547;
      }

      if (this.$vuetify.breakpoint.width >= 1904) {
        height = 800;
      }

      if (this.$vuetify.breakpoint.width >= 2300) {
        height = 1093;
      }

      if (this.$vuetify.breakpoint.width >= 2561) {
        height = 1640;
      }

      return height;
    },
  },
  data() {
    return {
      render: false,
      netZeroSingleton: {},
      dotsOwl: 2,
      open_menu: false,
      model: 0,
      tab: 0,
      expand: false,
      carouselZeroCombustion: 0,
      expansionPanels: 0,
      infoZeroCombustion: false,
      infoZeroCombustion2: true,
      expand2: false,
    };
  },
  watch: {
    $route() {
      this.open_menu = false;
    },
    model: function () {
      this.$vuetify.goTo("#tabs");
    },
  },
  created() {
    fetch(
      "https://content.marisolmalibu.com/api/singletons/get/netZero"
    )
      .then((data) => data.json())
      .then((data) => (this.netZeroSingleton = data))
      .then((this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$gtag.pageview({ page_path: '/net-zero' ,page_name: 'Net Zero'})
    window.addEventListener("scroll", this.onScrollCount, { passive: true });

    this.$emit("update", 1);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollCount);
  },
  methods: {
    onScrollCount: function () {
      var parallax = document.querySelector(".parallax");
      var parallaxContainer = document.querySelector(".parallax-container");
      var rowParallax = document.querySelector(".row-parallax").offsetTop;
      var scrollTop = document.documentElement.scrollTop;
      var rowPosition = rowParallax - scrollTop;

      var textParallaxContainer = document.querySelector(
        "#textParallaxContainer"
      ).offsetTop;

      if (rowPosition > 0) {
        if (rowPosition < this.$vuetify.breakpoint.height) {
          var porcentaje =
            (this.$vuetify.breakpoint.height - rowPosition) /
            this.$vuetify.breakpoint.height;
          porcentaje = porcentaje * 0.5;
          porcentaje = 1.5 - porcentaje;
          parallax.style.transform = "scale(" + porcentaje + ")";

          var porcentaje2 =
            (this.$vuetify.breakpoint.height - rowPosition) /
            this.$vuetify.breakpoint.height;

          if (this.$vuetify.breakpoint.width < 599) {
            porcentaje2 = 15 * porcentaje2;
          } else {
            porcentaje2 = 50 * porcentaje2;
          }

          if (rowPosition < this.$vuetify.breakpoint.height) {
            parallaxContainer.style.marginLeft = porcentaje2 + "px";
            parallaxContainer.style.marginRight = porcentaje2 + "px";
          } else {
            parallaxContainer.style.marginLeft = "0px";
            parallaxContainer.style.marginRight = "0px";
          }
        }
      }

      var textParallaxPosition = textParallaxContainer - scrollTop;
      var parallaxText = document.querySelector("#textParallax");

      var altoParallax = 500;

      if (this.$vuetify.breakpoint.width == 768) {
        altoParallax = 720;
      }

      if (this.$vuetify.breakpoint.width > 1264) {
        altoParallax = 720;
      }

      var pctj =
        (this.$vuetify.breakpoint.height - textParallaxPosition) /
        this.$vuetify.breakpoint.height;

      parallaxText.style.transform =
        "translateY(" + pctj * -altoParallax + "px)";

      var textParallaxContainer2 = document.querySelector(
        "#textParallaxContainer2"
      ).offsetTop;

      var textParallaxPosition2 = textParallaxContainer2 - scrollTop;

      var parallaxText2 = document.querySelector("#textParallax2");

      var pctj2 =
        (this.$vuetify.breakpoint.height - textParallaxPosition2) /
        this.$vuetify.breakpoint.height;

      parallaxText2.style.transform =
        "translateY(" + pctj2 * -altoParallax + "px)";
    },
    changedOwlCarousel: function (e) {
      this.dotsOwl = e.item.index;
    },
    owlCarN: function () {
      // Create our event (with options)
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
      // elem.dispatchEvent(evt);
      // if(this.dotsOwl == 1){

      //   var elem = document.querySelector('#nextOwl');

      //   elem.dispatchEvent(evt);
      //   elem.dispatchEvent(evt);
      // }

      // if(this.dotsOwl == 2){

      //   var elem2 = document.querySelector('#nextOwl');
      //   elem2.dispatchEvent(evt);
      // }
    },
    owlCarN2: function () {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
    },
    owlCarN3: function () {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      var elem = document.querySelector("#nextOwl");

      elem.dispatchEvent(evt);
    },
  },
};
</script>
